export const gameCategories = [
    "action",
    "arcade",
    "puzzle",
    "strategy",
    "card",
    "casino",
    "dice",
    "sport",
    "board",
    "idle",
]

export function generateTitle(category) {
    const descriptors = {
        action: ["High-Speed Action Games"],
        arcade: ["Classic Arcade Hits"],
        puzzle: ["Brain-Teasing Puzzles"],
        strategy: ["Tactical Strategy Games"],
        card: ["Classic Card Games"],
        casino: ["Exciting Casino Style Games"],
        dice: ["Strategic Dice Rolling"],
        sport: ["Exciting Sports Games"],
        board: ["Classic Board Games"],
        idle: ["Casual Clicker Fun"],
        default: ["Fun Browser Games"]
    };

    const callsToAction = {
        action: ["Play Now for Free!"],
        arcade: ["No Download Needed!"],
        puzzle: ["Play Instantly Online!"],
        strategy: ["Join the Fun Today!"],
        card: ["Play Now for Free!"],
        casino: ["No Download Needed!"],
        dice: ["Play Instantly Online!"],
        sport: ["Join the Fun Today!"],
        board: ["Play Now for Free!"],
        idle: ["No Download Needed!"],
        default: ["Play Instantly Online!"]
    };

    const descriptor = descriptors[category] || descriptors["default"];
    const callToAction = callsToAction[category] || callsToAction["default"];
    return `CoffeeBreak Games | ${descriptor} – ${callToAction}`;
}

export function generateMetaDescription(category) {
    const descriptions = {
        action: [
            "Join the action and test your reflexes with the best free online action games. Jump into the excitement now!"
        ],
        arcade: [
            "Enjoy classic and modern arcade games online! Play retro hits and exciting new challenges for free.",
        ],
        puzzle: [
            "Challenge your mind with brain-teasing puzzle games! Solve tricky levels and have fun while sharpening your skills.",
        ],
        strategy: [
            "Plan, build, and conquer with free strategy games! Test your tactical skills in turn-based and real-time challenges.",
        ],
        card: [
            "From casual Solitaire to intense Poker, play the best online card games for free today!"
        ],
        casino: [
            "Spin the reels or test your poker skills with free casino games. No deposits, just fun!"
        ],
        dice: [
            "Enjoy classic and new dice games, from Yahtzee to exciting new challenges. Play instantly, no downloads!",
        ],
        sport: [
            "Test your skills in exciting sports games! From football to tennis, play for free and challenge friends.",
        ],
        board: [
            "Enjoy timeless board game classics or try new favorites! Play for free against friends or AI.",
        ],
        idle: [
            "Sit back and relax with fun and addictive idle games! Watch your progress grow while you play casually.",
        ],
        default: [
            "Play the best free online games! Instant fun with no downloads – explore action, arcade, puzzle, and more!",
        ]
    };

    const description = descriptions[category] || descriptions["default"];

    return description;
}
